import { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import NavbarBrand from "@material-tailwind/react/NavbarBrand";
import NavbarToggler from "@material-tailwind/react/NavbarToggler";
import NavbarCollapse from "@material-tailwind/react/NavbarCollapse";
import Nav from "@material-tailwind/react/Nav";
import NavLink from "@material-tailwind/react/NavLink";
import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import Icon from "@material-tailwind/react/Icon";
import Button from "@material-tailwind/react/Button";

export default function DefaultNavbar({ color = "white" }) {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Navbar color="transparent" navbar>
      <NavbarContainer>
        <NavbarWrapper>
          <Link to="/" ripple="light" className="cursor-pointer">
            <NavbarBrand color={color}>Our Gatekeeper</NavbarBrand>
          </Link>

          <NavbarToggler
            onClick={() => setOpenNavbar(!openNavbar)}
            color="white"
          />
        </NavbarWrapper>

        <NavbarCollapse open={openNavbar}>
          <Nav>
            <div className="w-full flex justify-end">
              <div className="flex flex-col z-50 lg:flex-row lg:items-center ">
                {/* <Link to="/login"> */}
                <Button
                  color="transparent"
                  className="bg-white text-black lg:ml-4"
                  ripple="dark"
                  onClick={() => {
                    const url =
                      process.env.NODE_ENV === "development"
                        ? `http://localhost:3000/login`
                        : `https://admin.ourgatekeeper.com/login`;
                    window.open(url, "_self");
                  }}
                >
                  Admin Login
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </Nav>
        </NavbarCollapse>
      </NavbarContainer>
    </Navbar>
  );
}
