import React, { useRef } from "react";
import Button from "@material-tailwind/react/Button";
import FlipPage from "react-flip-page";

import "./flippage.css";
const FirstComponent = () => (
  <div className="flex items-center justify-center  h-full ">
    <div className="first-component text-white h-[400px] flex flex-col justify-center text-center items-center w-7/12">
      <div>
        <h2 className="text-4xl font-bold ">Our Gatekeeper</h2>
        <p>How It Works</p>
      </div>
    </div>
    <img
      className="h-[400px]"
      src={require("../../assets/img/logo.png").default}
      alt="Gatekeeper Logo"
    />
  </div>
);

const TextComp = ({ list, index }) => {
  return (
    <div className="flex flex-col items-start justify-center h-full relative px-10 bg-howto-background w-full">
      <div className="w-full" dangerouslySetInnerHTML={{ __html: list }} />
      <div className="absolute bottom-0 right-0">Page {index}</div>
    </div>
  );
};

const OtherComp = ({ title, src, index }) => {
  return (
    <div className="flex flex-col items-center justify-center relative">
      <img className="" src={src} alt={title} />
      <div className="absolute bottom-0 right-0">Page {index}</div>
    </div>
  );
};
export default function Content() {
  let flipPageRef = useRef(null);
  const pages = [
    {
      component: <FirstComponent />,
    },
    {
      component: (
        <TextComp
          index={2}
          list={`<h2 class="text-4xl font-bold mb-2 lg:mb-10">Introduction</h2>
            <ul class="list-disc capitalize lg:text-lg">
                  <li
                    className="capitalize"
                  >
                  My name is Andrew Otoo, I am the product manager for ‘Our Gatekeeper’
                  I will be presenting to you our estate management software suite
                  called <b>Our Gatekeeper</b>
                  </li>
                  <li>
                  LYME DRIP IS A SOLUTIONS COMPANY THAT INVESTIGATES ROOT CAUSES OF
PROBLEMS AND PROVIDES A THOROUGH SOFTWARE BASED SOLUTIONS OUR
MISSION IS TO ENHANCE SAFE COMMUNITY LIVING WITH THE PROVISION OF
CONVENIENCE AND SECURITY FEATURES THROUGH A MOBILE APPLICATION
SUITE THIS USER APPLICATION IS CALLED “OUR GATEKEEPER” AND IS AVAILABLE
ON BOTH ANDROID AND IOS PLATFORMS
                  </li>
            </ul>`}
        />
      ),
    },
    {
      component: (
        <TextComp
          index={3}
          list={`<h2 class="text-4xl font-bold mb-2">Solutions</h2>
          <p class='lg:text-lg'>Below are the identified issues we hope to fix with Our Gatekeeper Suite:</p>
          <p class='font-bold lg:text-lg'>Security</p>
            <ul class="list-decimal capitalize lg:text-lg">
                  <li>Database of guest name, phone number and time of entry to the estate</li>
                  <li>Database of residents who requested for guests entry</li>
                  <li>Database of security personnel who allowed or disallowed guests with time and reason</li>
                  <li>Send alert to notify a 500m radius or/and specified contacts about any security situation</li>
                  <li>Live dashboard displaying relevant resident and access information</li>

                  <p class='mt-5 font-bold text-lg'>Convenience</p>
                  <li>Create and share access requests with guests, indicating number of entrants and expiry period of visit</li>
                  <li>Conveniently share access code through all sharable platforms right from your phone</li>
                  <li>Make estate levy payments right from the app (Mobile Money)</li>
                  <li>Get instant notification about your guest status at the gate (Allowed or Denied)</li>
                  <li>Check Estate levy payments paid and owed</li>
                  <li>Check Estate project payments paid and owed</li>
                  <li>Message exchange between Resident and Estate Administrator</li>
            </ul>`}
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={4}
          src={require("../../assets/img/howto/1.png").default}
          imageUrl="../../assets/img/howto/1.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={5}
          src={require("../../assets/img/howto/2.png").default}
          imageUrl="../../assets/img/howto/2.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={6}
          src={require("../../assets/img/howto/3.png").default}
          imageUrl="../../assets/img/howto/3.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={7}
          src={require("../../assets/img/howto/4.png").default}
          imageUrl="../../assets/img/howto/4.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={8}
          src={require("../../assets/img/howto/8.png").default}
          imageUrl="../../assets/img/howto/8.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={9}
          src={require("../../assets/img/howto/9.png").default}
          imageUrl="../../assets/img/howto/9.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={10}
          src={require("../../assets/img/howto/10.png").default}
          imageUrl="../../assets/img/howto/10.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={11}
          src={require("../../assets/img/howto/11.png").default}
          imageUrl="../../assets/img/howto/11.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={12}
          src={require("../../assets/img/howto/12.png").default}
          imageUrl="../../assets/img/howto/12.png"
        />
      ),
    },
    {
      component: (
        <OtherComp
          index={13}
          src={require("../../assets/img/howto/13.png").default}
          imageUrl="../../assets/img/howto/13.png"
        />
      ),
    },
    {
      component: (
        <TextComp
          index={14}
          list={`
          <h2 class="text-4xl">Key Benefits of Service</h2>
          <h2 class="text-3xl font-bold mb-5">Benefits of launching Our Gatekeeper</h2>
            <ul class="list-decimal capitalize lg:text-lg">
                  <li>Enhanced estate security for the residents</li>
                  <li>Ease of access to estate by guests</li>
                  <li>Time spent at gate is greatly reduced</li>
                  <li>Database of Residents whether home owners or tenant is captured</li>
                  <li>Database of gate officers and their activities is captured</li>
            </ul>`}
        />
      ),
    },
    {
      component: (
        <TextComp
          index={15}
          list={`
          <h2 class="text-4xl text-center text-white uppercase">Contact Information</h2>

          <div class='text-3xl'>
         
         <p>Customer Manager</p>
         <p>David Adu</p>
         <p>+233200050072</p>


         <p class='mt-24'>Website: <a href='www.ourgatekeeper.com'>www.ourgatekeeper.com</a></p>
         <p>Email: <a mailto='info@ourgatekeeper.com'>info@ourgatekeeper.com</a></p>
         <p>Phone number: 0200050072</p>
         </div>
           `}
        />
      ),
    },
  ];

  return (
    <section className="relative py-16 bg-gray-100">
      <div className="container max-w-7xl px-4 mx-auto">
        <div className="flex flex-col  bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
          <div className="px-6 pt-6 border-red-500">
            <FlipPage
              ref={(component) => {
                flipPageRef = component;
              }}
              firstComponent={FirstComponent}
              pageBackground="#eee"
              className="flip-book"
              showSwipeHint
              showHint
              width={"100%"}
              // height={"100%"}
              style={{
                width: "100%",
                height: "100%",
              }}
              height={600}
              uncutPages
              orientation="horizontal"
            >
              {pages.map((page, index) => (
                <>
                  {page.component || (
                    <article key={index} className="flip-page">
                      <h2 className="page-title">{page.title}</h2>
                      <div className="flip-page-content">{page.content}</div>
                      <div className="page-number">Page {index + 1}</div>
                    </article>
                  )}
                </>
              ))}
            </FlipPage>
          </div>
          <div className="flex w-full px-4 justify-center my-3">
            <div className="flex justify-between lg:w-3/12">
              <Button
                size="sm"
                color="lightBlue"
                ripple="light"
                onClick={() => {
                  flipPageRef.gotoPreviousPage();
                }}
              >
                Previous
              </Button>
              <Button
                size="sm"
                color="lightBlue"
                ripple="light"
                onClick={() => {
                  flipPageRef.gotoNextPage();
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
