import Title from "components/landing/Title";
import ContactCard from "components/landing/ContactCard";
import Form from "components/landing/Form";

export default function ContactSection() {
  return (
    <>
      <section className="pb-10 relative block bg-gray-300">
        <div className="container max-w-7xl mx-auto px-4 lg:pt-10">
          <Title heading="WHY USE OUR GATEKEEPER?" />

          <div className="flex flex-wrap -mt-12 justify-center">
            <ContactCard
              icon="phone_iphone"
              title="Multi User Interface"
              iconBg="bg-blue-500"
              iconColor="white"
            >
              Gain access to a complete suite with seemless interaction between
              estate management, security, residents and guests.
            </ContactCard>
            <ContactCard
              icon="warning_amber"
              title="Panic Button"
              iconBg="bg-red-500"
              iconColor="white"
            >
              Send out instant panic notification to all recipients within
              kilometers of range or to specific contact numbers (on the
              platform)
            </ContactCard>
            <ContactCard
              icon="lock_open"
              title="Convenient Guest Access"
              iconBg="bg-green-500"
              iconColor="white"
            >
              Guest codes created by residents are easily scanned at the gate,
              notification is sent to resident
            </ContactCard>
            <ContactCard
              icon="login"
              title="Entry And Exit Management"
              iconBg="bg-yellow-500"
              iconColor="white"
            >
              Realtime information on residents creating requests and guests
              entry or denial to premises.
            </ContactCard>
            <ContactCard
              icon="campaign"
              title="Estate Level Communication"
              iconBg="bg-purple-500"
              iconColor="white"
            >
              Send messages to individual residents or to the whole estate
              instantly
            </ContactCard>
            <ContactCard
              icon="attach_money"
              title="Flexible Payments"
              iconBg="bg-green-800"
              iconColor="white"
            >
              Make payments per property per quarter for long term savings
              tailored to your estate
            </ContactCard>
            <ContactCard
              icon="settings_suggest"
              title="Customization"
              iconBg="bg-blue-800"
              iconColor="white"
            >
              Make customization requests a reality with Our Gatekeeper.
            </ContactCard>
            <ContactCard
              icon="support_agent"
              title="24/7 Support"
              iconBg="bg-blue-200"
              iconColor="white"
            >
              Guaranteed 24/7 support assistance available at your convenience
            </ContactCard>
          </div>
        </div>
      </section>

      <section className="pb-10 relative block bg-gray-200">
        <div className="container max-w-7xl mx-auto px-4 lg:pt-10">
          <Title heading="Secure your premises">
            Wherever there is a 'gate', Our Gatekeeper can secure entry and exit
            of guests/visitors/service people in and out of your premises. Our
            number one commitment is to make sure everyone in your vicinity has
            been vetted and approved for entry.
          </Title>

          <div className="flex flex-wrap -mt-12 justify-center">
            <ContactCard icon="stars" title="Excellent Platform Services">
              Our platforms are fast and up-to-date. We provide the latest
              technologies and update our systems reguarly to 'fish' out new
              securiy threats.
            </ContactCard>
            <ContactCard icon="insert_chart" title="Data Protection">
              We protect your data. We are registered with the Data Protecion
              Agency and be rest assured you data is safe with us
            </ContactCard>
            <ContactCard icon="launch" title="Launch Time">
              We integrate and launch with your systems very quickly. We provide
              personalised services and we are on standby to answer all of your
              complaints
            </ContactCard>
          </div>
        </div>
      </section>

      <section className="pb-10 relative block bg-gray-100">
        <div className="container max-w-7xl mx-auto px-4 lg:pt-10">
          <Title heading="Packages" />

          <div className="flex flex-wrap -mt-12 justify-center">
            <ContactCard
              icon="check"
              title="FREEMIUM"
              iconBg="bg-red-500"
              iconColor="white"
            >
              Skinned down version of the application suite with non-intrusive
              advertisement
            </ContactCard>
            <ContactCard
              icon="check"
              title="PREMIUM"
              iconBg="bg-yellow-500"
              iconColor="white"
            >
              Full version of the application suite
            </ContactCard>
            <ContactCard
              icon="check"
              title="CUSTOM"
              iconBg="bg-green-500"
              iconColor="white"
            >
              Bespoke functions tailored to your needs in the shortest possible
              time
            </ContactCard>
          </div>

          <Form />
        </div>
      </section>
    </>
  );
}
