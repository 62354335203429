import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getFirestore, doc, getDoc, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const database = getDatabase(app);

export const loginWithEmail = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

export const registerWithEmail = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);

export const logout = () => {
  signOut(auth);
};

export const passwordReset = (email) => sendPasswordResetEmail(auth, email);

export const getCurrentUser = () => {
  try {
    const user = auth.currentUser;
    return user;
  } catch (error) {
    console.log(error);
  }
};

export const findUserInDB = async ({ phone }) => {
  try {
    const {
      data: { message, success },
    } = await axios.post(
      `https://us-central1-estate-security-app.cloudfunctions.net/findUserInAuth?phone=${phone}`
    );

    if (!success || !message) return null;

    const { customClaims, uid } = message;

    const userDoc = doc(
      db,
      `estates/${customClaims?.estateID}/${customClaims?.userGroup}/${uid}`
    );
    const snapshot = await getDoc(userDoc);
    if (!snapshot.exists()) return null;

    return snapshot.data();
  } catch (error) {
    console.log(error);
  }
};

export const userOfficer = (userGroup) => {
  return [`officers`].includes(userGroup);
};

export const userResident = (userGroup) => {
  return [`residents`].includes(userGroup);
};
