import H3 from "@material-tailwind/react/Heading3";
import Paragraph from "@material-tailwind/react/Paragraph";
import Input from "@material-tailwind/react/Input";
import Textarea from "@material-tailwind/react/Textarea";
import Button from "@material-tailwind/react/Button";
import { useForm, Controller } from "react-hook-form";
import React from "react";

export default function Form() {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("Send Message");

  const sendEmail = async (values) => {
    try {
      setProcessing(true);
      setButtonText("Sending...");
      const res = await fetch(
        `https://us-central1-our-gatekeeper.cloudfunctions.net/sendEmail`,
        {
          method: "post",
          body: JSON.stringify(values),
        }
      );
      const data = await res.json();
      console.log(data);
      reset({
        name: "",
        email: "",
        message: "",
      });
      setButtonText("Sent");
      setTimeout(() => {
        setButtonText("Send Message");
      }, 500);
    } catch (error) {
      console.log(error);
      setButtonText("Error");
      setTimeout(() => {
        setButtonText("Send Message");
      }, 500);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="flex flex-wrap justify-center mt-24">
      <div className="w-full lg:w-8/12 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <div className="flex-auto p-5 lg:p-10">
            <div className="w-full text-center">
              <H3 color="gray">Want to work with us?</H3>
              <Paragraph color="blueGray">
                Complete this form and we will get back to you in 24 hours.
              </Paragraph>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="flex gap-8 mt-16 mb-12">
                <div>
                  <Controller
                    control={control}
                    rules={{ required: "Name is required" }}
                    name="name"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Input
                        type="text"
                        placeholder="Full Name"
                        color="lightBlue"
                        value={value}
                        onChange={onChange}
                        inputRef={ref} // wire up the input ref
                      />
                    )}
                  />
                  <p className="text-xs text-red-500">
                    {errors?.name?.message}
                  </p>
                </div>
                <div>
                  <Controller
                    control={control}
                    rules={{ required: "Email is required" }}
                    name="email"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Input
                        type="email"
                        placeholder="Email Address"
                        color="lightBlue"
                        value={value}
                        onChange={onChange}
                        inputRef={ref} // wire up the input ref
                      />
                    )}
                  />

                  <p className="text-xs text-red-500">
                    {errors?.email?.message}
                  </p>
                </div>
              </div>

              <Controller
                control={control}
                rules={{ required: "Message is required" }}
                name="message"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Textarea
                    color="lightBlue"
                    placeholder="Message"
                    value={value}
                    onChange={onChange}
                    inputRef={ref} // wire up the input ref
                  />
                )}
              />

              <p className="text-xs text-red-500">{errors?.message?.message}</p>

              <div className="flex justify-center mt-10">
                <Button
                  disabled={processing}
                  color="lightBlue"
                  ripple="light"
                  onClick={handleSubmit(sendEmail)}
                >
                  {buttonText}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
