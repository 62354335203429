import H2 from "@material-tailwind/react/Heading2";
import LeadText from "@material-tailwind/react/LeadText";
import React from "react";

const initial = {
  url: "bg-landing-background_2",
  props: {
    backgroundPosition: "65% bottom",
  },
};

export default function Header() {
  const [initialProps, setInitialProps] = React.useState(initial);
  const [bgProps, setBgProps] = React.useState({
    url: "bg-landing-background",
    props: {
      backgroundPosition: "28% top",
    },
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      setInitialProps(bgProps);
      setBgProps(initialProps);
    }, 5000);

    return () => clearInterval(interval);
  }, [bgProps, initialProps]);

  // console.log(bgProps);

  return (
    <div className="relative pt-16 flex content-center items-center justify-center h-[50vh] lg:h-[70vh]">
      <div
        className={`${bgProps.url} transition-all duration-500 ease-in-out bg-cover bg-center absolute top-0 w-full h-full`}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundOrigin: "content-box",
          ...bgProps.props,
        }}
      />
      <div className="container max-w-8xl relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="w-full lg:w-11/12 px-4 ml-auto mr-auto text-center">
            <h2 className="text-white font-semibold text-2xl lg:text-6xl">
              WELCOME TO THE FIRST BESPOKE ESTATE SECURITY AND CONVENIENCE
              SERVICE PROVIDER IN GHANA
            </h2>
            <div className="text-gray-200">
              <LeadText color="gray-200">
                Our Gatekeeper provides convenience and security services for
                our community.
              </LeadText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
