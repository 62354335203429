import { Routes, Route } from "react-router-dom";
import Landing from "pages/Landing";
import Login from "pages/Login";
import Register from "pages/Register";
import ReactGA from "react-ga";
// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import Privacy from "pages/Privacy";
import HowItWorks from "pages/HowItWorks";
import { useEffect } from "react";

const TRACKING_ID = "UA-261507338-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/privacy" element={<Privacy />} />
      <Route exact path="/how-it-works" element={<HowItWorks />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
    </Routes>
  );
}

export default App;
